<template>
    <!-- wrapperClass라는 computed를 통해 width/height 속성을 동적으로 Tailwind 클래스로 생성 -->
    <div :class="wrapperClass">
      <img
        class="lg:w-[72px] lg:h-[72px]"
        :src="avaratUrl"
        alt=""
      />
      <div class="lg:flex lg:flex-col gap-[10px]">
        <p class="text-main-1">{{ name }}</p>
        <p class="lg:text-content-size font-medium text-gray_1">{{ description }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      avaratUrl: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      },
      width: {
        type: Number,
        default: 646
      },
      height: {
        type: Number,
        default: 104
      }
    },
    computed: {
      wrapperClass() {
        return [
          // 기본 Tailwind 클래스들
          'lg:flex',
          'lg:gap-6',
          'rounded-xl',
          'lg:ml-[16px]',
          'lg:pt-[18px]',
          'bg-white',
          // width/height를 px 단위로 반영 (Tailwind 임의 값 문법)
          `lg:w-[${this.width}px]`,
          `lg:h-[${this.height}px]`
        ]
      }
    }
  }
  </script>
  
<template>
    <div class="lg:w-[620px] lg:h-[148px] lg:flex lg:pl-4 bg-white rounded-xl">
        <img class="lg:w-[78px] lg:h-[78px] lg:mt-3" :src="avaratUrl" alt="">
        <div class="lg:mt-3 lg:ml-6 lg:flex lg:flex-col lg:gap-y-[10px]">
            <p class="text-main-1 lg:text-small-size">{{ name }}</p>
            <p class="text-gray_1 lg:text-content-size font-medium">{{ title }}</p>
            <p class="text-text-1 lg:text-small-size lg:w-[458px]" v-html="description"></p>
        </div>
    </div>
</template>
<script>
export default {
    props: ['avaratUrl', 'name', 'title', 'description'],
    data() {
        return {
        }
    }
}
</script>
<template lang="">
  <div class="py-10 bg-slate-300 inset-x-0 bottom-0 flex  sm:flex-col text-small-size justify-between items-start font-basic">
    <img class="lg:px-32 lg_tall:px-32 sm:w-[40%] sm:ml-4" src="@/assets/ic_leadwalk_logl.png" alt="">
    <div class="sm:px-10 flex flex-col justify-between sm:text-small-size text-[18px] text-text-0">
      <p class="">회사명: (주)리드워크</p>
      <p class="">주소: 서울시 금천구 디지털로9길 41 삼성IT해링턴타워 1016호</p>
      <p class="">사업자 등록번호: 704-81-01605</p>
      <p class="">대표자: 이재문</p>
    </div>
    <div class="sm:px-10 flex flex-col lg:h-[120px] justify-between sm:text-small-size text-[18px] text-text-0 sm:mt-5">
      <p class="">Email: contact@l-walk.com</p>
      <p class="">Fax: 02-2085-0607</p>
      <p class="">Tel: 02-2085-0606</p>
    </div>
    <div class="sm:px-10 flex flex-col lg:h-[120px] justify-between sm:text-small-size text-[18px] text-text-0 sm:mt-5">
      <p class="cursor-pointer" @click="goToPage('/terms') ">이용 약관</p>
      <p class="cursor-pointer" @click="goToPage('/terms') ">개인정보처리방침</p>
      <p>Copyright 2023 All rights reserved - l-walk (주)리드워크</p>
    </div>
    <div class="lg:self-end sm:mt-5">      
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goToPage(path) {
      this.$router.push(path)
    }    
  }  
}
</script>
<template>
    <div class="lg:w-[630px] lg:h-[120px] lg:flex lg:flex-col lg:items-center lg:justify-center lg:rounded-2xl border-main-0 border-[1px]
        sm:h-[100px] sm:flex sm:flex-col sm:items-center sm:justify-center sm:rounded-lg">
        <p class="lg:font-semibold lg:text-content-size sm:font-semibold text-main-1">{{ title }}</p>
        <p class="lg:text-small-size sm:text-small-size-1 sm:mx-4 sm:font-medium sm:text-center mt-3 text-gray_1" v-html="description"></p>
    </div>
</template>
<script>
export default {
    props: ['title', 'description'],
    data() {
        return {

        }
    }
}
</script>

<template>
    <div :class="rootStyle">
        <div class="sm:flex sm:flex-col sm:gap-[10px]">
            <p v-if="name" class="sm:text-small-size-1 text-main-1">{{ name }}</p>
            <p v-if="title" class="font-medium text-gray_1">{{ title }}</p>
            <p v-if="description" :class="descriptionStype" v-html="description"></p>
        </div>
        <img class="sm:w-[48px] sm:h-[48px]" :src="avaratUrl" alt="">
    </div>
</template>
<script>
export default {
    props: ['avaratUrl', 'name', 'title', 'description', 'descriptionStype', 'rootStyle'],
    data() {
        return {
        }
    }
}
</script>
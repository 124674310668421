<template>
  <Header />
  <div class="w-full font-basic text-text-0 mt-28">

    <!-- 겟아이즈 간단소개 -->
    <div class="flex sm:flex-col items-center justify-center sm:gap-10 lg:gap-20 lg:px-[10%] lg_tall:gap-20 lg_tall:px-[10%] sm:px-[10%] lg:py-24 lg_tall:py-24 sm:py-2">
      <div class="flex-col sm:mx-auto">
        <p class="font-semibold lg:text-title-size lg_tall:text-title-size sm:text-medium-size text-main-0">Geteyes</p>
        <p class="font-bold lg:text-title-size lg_tall:text-[42px] sm:text-medium-size mt-4">현명한 소비 플랫폼</p>
        <p class="mt-4 lg:text-content-size lg_tall:text-content-size ">소중한 사람에게 선물할때는, 겟아이즈!</p>
        <p class="lg:text-content-size lg_tall:text-content-size">겟아이즈에서 무료로 상품을 경험하고<br class="lg_tall:hidden">소중한 사람에게 선물도 해요!</p>
        <div class="flex gap-3 mt-4">
          <a href="https://apps.apple.com/kr/app/%EA%B2%9F%EC%95%84%EC%9D%B4%EC%A6%88-%ED%98%84%EB%AA%85%ED%95%9C-%EC%86%8C%EB%B9%84-%ED%94%8C%EB%9E%AB%ED%8F%BC/id1535913367"
            class="py-2 lg:px-8 lg_tall:px-8 sm:px-4 border border-text-1 rounded-3xl flex items-center sm:justify-center lg:justify-between lg_tall:justify-between gap-2" target="_blank">
              <i class="fa-brands fa-apple"></i>
              <p class="font-semibold text-text-1">APP Store</p>
          </a>

          <a href=" https://play.google.com/store/apps/details?id=com.leadwalk.geteyes"
          class="py-2 lg:px-8 lg_tall:px-8 sm:px-4 border border-text-1 rounded-3xl flex items-center sm:justify-center lg:justify-between lg_tall:justify-between gap-2" target="_blank">
            <i class="fa-brands fa-google-play"></i>
            <p class="font-semibold text-text-1">Google Play</p>
          </a>
        </div>
      </div>
      <div class="sm:order-last">
        <img class="lg:object-fill lg_tall:object-fill sm:object-cover lg:h-[650px] lg_tall:h-[600px]" src="https://d3hprqr5b1tsx3.cloudfront.net/img/202401/geteyes_106.jpg" alt="">
      </div>
    </div>

    <!-- 겟아이즈 주요수치 -->
    <div class="lg:px-[10%] lg_tall:px-[10%] sm:px-10 lg:py-24 lg_tall:py-24 sm:py-16 text-text-0 bg-slate-50">
      <p class="font-bold lg:text-title-size lg_tall:text-title-size sm:text-medium-size text-center ">겟아이즈 주요 수치</p>
      <p class="lg:text-medium-size lg_tall:text-medium-size sm:text-content-size text-center text-text-1 mt-10"> 매년 겟아이즈는 빠르게 성장해 나아가고 있어요.</p>
      <div class="flex flex-wrap mt-20 justify-between lg:w-[80%] lg_tall:w-[80%] mx-auto scrolltrigger">
        <div class="w-[260px] lg:h-[200px] lg_tall:h-[200px] sm:h-[140px] sm:mx-auto">
          <i class="fa-solid fa-download fa-3x flex justify-center"
            :class="[isMobile ? 'fa-1x' : 'fa-3x']">
          </i>
          <p class="text-center lg:text-medium-size lg_tall:text-medium-size sm:text-content-size mt-4 font-bold">누적 다운로드 수</p>
          <p class="text-center mt-4 lg:text-title-size lg_tall:text-title-size sm:text-medium-size font-bold text-main-0">{{ downLoadCount
          }} +</p>
        </div>
        <div class="w-[260px] lg:h-[200px] lg_tall:h-[200px] sm:h-[140px] sm:mx-auto sm:mt-6">
          <i class="fa-solid fa-user fa-3x flex justify-center"
          :class="[isMobile ? 'fa-1x' : 'fa-3x']">
          </i>
          <p class="text-center lg:text-medium-size lg_tall:text-medium-size sm:text-content-size mt-4 font-bold">이용자 수</p>
          <p class="text-center mt-4 lg:text-title-size lg_tall:text-title-size sm:text-medium-size font-bold text-main-0">{{ userCount }}명
          </p>
        </div>
        <div class="w-[260px] lg:h-[200px] lg_tall:h-[200px] sm:h-[140px] sm:mx-auto sm:mt-6">
          <i class="fa-solid fa-arrow-up-right-dots fa-3x flex justify-center"
          :class="[isMobile ? 'fa-1x' : 'fa-3x']">
          </i>
          <p class="text-center lg:text-medium-size lg_tall:text-medium-size sm:text-content-size mt-4 font-bold">매달 성장율</p>
          <p class="text-center mt-4 lg:text-title-size lg_tall:text-title-size sm:text-medium-size font-bold text-main-0">{{ gorwCount }}%
          </p>
        </div>
        <div class="w-[260px] lg:h-[200px] lg_tall:h-[200px] sm:h-[140px] sm:mx-auto sm:mt-6">
          <i class="fa-solid fa-star fa-3x flex justify-center"
          :class="[isMobile ? 'fa-1x' : 'fa-3x']">
        </i>
          <p class="text-center lg:text-medium-size lg_tall:text-medium-size sm:text-content-size mt-4 font-bold">고객 만족도</p>
          <p class="text-center mt-4 lg:text-title-size lg_tall:text-title-size sm:text-medium-size font-bold text-main-0">{{ customerCount
          }}%</p>
        </div>
      </div>
    </div>

    <!-- 겟아이즈 서비스 소개 -->
    <div class="lg:px-[10%] lg_tall:px-[10%] sm:px-10 lg:py-24 lg_tall:py-24 sm:py-16">

      <p class="font-bold lg:text-title-size lg_tall:text-title-size sm:text-medium-size text-center">겟아이즈 서비스 소개</p>
      <!-- 선물하기 -->
      <div class="flex sm:flex-col items-center justify-center lg:gap-20 lg_tall:gap-20 sm:gap-10 lg:mt-20 lg_tall:mt-20 sm:mt-10">
        <img class="lg:object-fill lg_tall:object-fill sm:object-cover" src="https://d3hprqr5b1tsx3.cloudfront.net/img/202401/geteyes_service_001.png" alt="">
        <div class="flex flex-col ">
          <p class="lg:text-title-size lg_tall:text-title-size sm:text-medium-size text-main-0 font-semibold">선물하기</p>
          <p class="mt-5 lg:text-content-size lg_tall:text-content-size">테마별, 랭킹 및 가격대별로 겟아이즈의 인기 선물을 확인할 수 있어요<br>고민없이 지인에게 마음을 전해보세요<br>배송상품부터 모바일 교환권까지 다양한 상품들이 있어요</p>
        </div>        
      </div>

      <!-- G-Story -->
      <div class="flex sm:flex-col items-center justify-center lg:gap-20 lg_tall:gap-20 sm:gap-10 lg:mt-20 lg_tall:mt-20 sm:mt-10">
        <img class="lg:object-fill lg_tall:object-fill sm:object-cover order-last" src="https://d3hprqr5b1tsx3.cloudfront.net/img/202401/geteyes_service_002.png" alt="">
        <div class="flex flex-col sm:order-last">
          <p class="lg:text-title-size lg_tall:text-title-size sm:text-medium-size text-main-0 font-semibold">G-Story</p>
          <p class="mt-5 lg:text-content-size lg_tall:text-content-size">상품 구입 전 고민이 되신다면, 상품에 대한 리뷰로<br>정보를 얻고 게시물에 댓글을 남겨<br>상품을 구매한 유저와 소통해요.</p>
        </div>        
      </div>

      <!-- 브랜드 샵 -->
      <div class="flex sm:flex-col items-center justify-center lg:gap-20 lg_tall:gap-20 sm:gap-10 lg:mt-20 lg_tall:mt-20 sm:mt-10">
        <img class="lg:object-fill lg_tall:object-fill sm:object-cover" src="https://d3hprqr5b1tsx3.cloudfront.net/img/202401/geteyes_service_003.png" alt="">
        <div class="flex flex-col ">
          <p class="lg:text-title-size lg_tall:text-title-size sm:text-medium-size text-main-0 font-semibold">Brand Shop</p>
          <p class="mt-5 lg:text-content-size lg_tall:text-content-size">사용자들의 다양한 취향과 필요을 충족시키기 위해<br>세심하게 선별된 다양한 제품들을 확인해 보세요</p>
        </div>        
      </div>

      <!-- 두둑-->
      <div class="flex sm:flex-col items-center justify-center lg:gap-20 lg_tall:gap-20 sm:gap-10 lg:mt-20 lg_tall:mt-20 sm:mt-10">
        <img class="lg:object-fill lg_tall:object-fill sm:object-cover order-last" src="https://d3hprqr5b1tsx3.cloudfront.net/img/202401/geteyes_service_004.png" alt="">
        <div class="flex flex-col sm:order-last">
          <p class="lg:text-title-size lg_tall:text-title-size sm:text-medium-size text-main-0 font-semibold">두둑(오픈 준비중)</p>
          <p class="mt-5 lg:text-content-size lg_tall:text-content-size">원하는 제품을 선택하고 미션을 달성하여<br>게이지를 다 채우면 제품을 무료로 얻을 수 있어요<br></p>
        </div>        
      </div>

      <div class="grid grid-rows-2 grid-cols-2 mt-10 w-[50%] mx-auto hidden">
        <ServiceInfo_04 class="hidden" imgUrl="https://d3hprqr5b1tsx3.cloudfront.net/img/202312/geteyes_003.png"
          title="선물하기" content="간편하게 소중한 사람들에게 마음을 전달해 보세요." />
        <ServiceInfo_04 class="hidden" imgUrl="https://d3hprqr5b1tsx3.cloudfront.net/img/202312/geteyes_003.png"
          title="선물하기" content="간편하게 소중한 사람들에게 마음을 전달해 보세요." />
        <ServiceInfo_04 class="mt-10 hidden" imgUrl="https://d3hprqr5b1tsx3.cloudfront.net/img/202312/geteyes_003.png"
          title="선물하기" content="간편하게 소중한 사람들에게 마음을 전달해 보세요." />
        <ServiceInfo_04 class="mt-10 hidden" imgUrl="https://d3hprqr5b1tsx3.cloudfront.net/img/202312/geteyes_003.png"
          title="선물하기" content="간편하게 소중한 사람들에게 마음을 전달해 보세요." />
      </div>
    </div>

    <!-- 입점관련 -->
    <div class="lg:py-24 lg_tall:py-24 sm:py-16 text-text-0 bg-slate-50">
      <p class="font-bold lg:text-title-size lg_tall:text-title-size sm:text-medium-size text-center">겟아이즈 입점 혜택</p>
      <p class="lg:text-medium-size lg_tall:text-medium-size sm:text-[18px] text-center text-text-1 mt-10">겟아이즈의 다양한 입점 혜택을 지금 누려보세요.</p>
      <div class="flex sm:flex-col items-center justify-between px-[16%] lg:gap-40 lg_tall:gap-40 sm:gap-20 lg:mt-10 lg_tall:mt-10 sm:mt-10">
        <div class="flex lg:flex-col lg_tall:flex-col sm:flex-wrap">
          <div class="flex lg:flex-col lg_tall:flex-col justify-center items-center sm:mx-auto">
            <p class="font-bold lg:text-[140px] lg_tall:text-[140px] sm:text-[80px] text-main-0 text-center">0원</p>
          </div>

          <div class="flex lg_tall:mx-auto lg:gap-6 lg_tall:gap-6 sm:gap-4 sm:mx-auto">
            <div class="py-4 lg:px-8 lg_tall:px-8 sm:px-2 rounded-2xl bg-white border sm:text-center sm:hidden border-gray_4">
              <p class="lg:text-medium-size lg_tall:text-medium-size sm:text-small-size">가입 비용</p>
              <p class="lg:text-title-size lg_tall:text-title-size sm:text-small-size font-bold text-center">0원</p>
            </div>
            <div class="py-4 px-4 rounded-2xl bg-white border text-center lg:hidden lg_tall:hidden border-gray_4">
              <p class="lg:text-medium-size lg_tall:text-medium-size sm:text-small-size">가입<br>비용</p>
              <p class="lg:text-title-size lg_tall:text-title-size sm:text-small-size font-bold text-center">0원</p>
            </div>
            <div class="py-4 lg:px-8 lg_tall:px-8 sm:px-2 rounded-2xl bg-white border sm:text-center sm:hidden border-gray_4">
              <p class="lg:text-medium-size lg_tall:text-medium-size sm:text-small-size">결제 수수료</p>
              <p class="lg:text-title-size lg_tall:text-title-size sm:text-small-size font-bold text-center">0원</p>
            </div>
            <div class="py-4 px-4 rounded-2xl bg-white border text-center lg:hidden lg_tall:hidden border-gray_4">
              <p class="lg:text-medium-size lg_tall:text-medium-size sm:text-small-size">결제<br>수수료</p>
              <p class="lg:text-title-size lg_tall:text-title-size sm:text-small-size font-bold text-center">0원</p>
            </div>
            <div class="py-4 lg:px-8 lg_tall:px-8 sm:px-2 rounded-2xl bg-white border sm:text-center sm:hidden border-gray_4">
              <p class="lg:text-medium-size lg_tall:text-medium-size sm:text-small-size">배송 수수료</p>
              <p class="lg:text-title-size lg_tall:text-title-size sm:text-small-size font-bold text-center">0원</p>
            </div>
            <div class="py-4 px-4 rounded-2xl bg-white border text-center lg:hidden lg_tall:hidden border-gray_4">
              <p class="lg:text-medium-size lg_tall:text-medium-size sm:text-small-size">배송<br>수수료</p>
              <p class="lg:text-title-size lg_tall:text-title-size sm:text-small-size font-bold text-center">0원</p>
            </div>
          </div>
        </div>
        <div class="">
          <p class="font-bold lg:text-title-size lg_tall:text-title-size sm:text-medium-size">입점비용 0원</p>
          <p class="mt-4 text-text-1 text-lg lg:text-medium-size lg_tall:text-medium-size sm:text-content-size hidden">쇼핑몰 수수료 너무나 과합니다.</p>
          <p class="text-text-1 text-lg lg:text-content-size sm:text-content-size">초기 부담스러웠던 마켓 운영비용 걱정하지 마세요</p>
          <p class="text-text-1 mt-4">- 가입 비용 부담 제로</p>
          <p class="text-text-1">- 결제 수수료 비용 부담 제로</p>
          <p class="text-text-1">- 배송 수수료 부담 제로</p>
        </div>
      </div>
      <div class="flex sm:flex-col items-center justify-between px-[16%] lg:gap-40 sm:gap-20 mt-20 hidden">
        <div class="flex lg:flex-col sm:flex-wrap">
          <div class="flex lg:flex-col justify-center items-center sm:mx-auto">
            <img class="mx-auto mb-4" src="@/assets/ic_marketing.png" alt="">
          </div>

          <div class="flex gap-6 sm:mx-auto">
            <div class="py-4 lg:px-8 sm:px-4 rounded-2xl bg-white border">
              <p class="lg:text-medium-size sm:text-content-size font-bold text-center">상품홍보</p>
            </div>
            <div class="py-4 lg:px-8 sm:px-4 rounded-2xl bg-white border">
              <p class="lg:text-medium-size sm:text-content-size font-bold text-center">마케팅 지원</p>
            </div>
            <div class="py-4 lg:px-8 sm:px-4 rounded-2xl bg-white border">
              <p class="lg:text-medium-size sm:text-content-size font-bold text-center">DATA제공</p>
            </div>
          </div>

        </div>
        <div class="">
          <p class="font-bold lg:text-title-size sm:text-medium-size">마케팅을 지원해드려요.</p>
          <p class="mt-4 text-text-1 text-lg">쇼핑몰 수수료 너무나 과합니다.</p>
          <p class="text-text-1 text-lg">초기 부담스러웠던 마켓 운영비용 걱정하지 마세요</p>
          <p class="text-text-1 mt-4">- 가입 비용 부담 제로</p>
          <p class="text-text-1">- 결제 수수료 비용 부담 제로</p>
          <p class="text-text-1">- 배송 수수료 부담 제로</p>
        </div>
      </div>
    </div>

    <!-- 입점절차 -->
    <div class="lg:py-24 lg_tall:py-24 sm:py-16 text-text-0">
      <p class="font-bold lg:text-title-size lg_tall:text-title-size sm:text-medium-size text-center">겟아이즈 입점절차</p>
      <p class="lg:text-medium-size lg_tall:text-medium-size sm:text-content-size text-center text-text-1 mt-10 sm:hidden">판매자와 소비자 연결의 첫 걸음 겟아이즈를 통해 시작해보세요.</p>
      <p class="lg:text-medium-size lg_tall:text-medium-size sm:text-content-size text-center text-text-1 mt-10 lg:hidden lg_tall:hidden">판매자와 소비자 연결의 첫 걸음<br>겟아이즈를 통해 시작해보세요.</p>
      <div class="flex lg:flex-wrap lg_tall:flex-wrap sm:flex-col lg:mt-20 lg_tall:mt-20 sm:mt-10 items-center justify-center gap-8">
        <div class="flex flex-col items-center justify-between">
          <i class="fa-solid fa-file-signature "
          :class="[isMobile ? 'fa-3x' : 'fa-6x']">
          </i>
          <p class="lg:text-content-size lg_tall:text-content-size sm:text-content-size text-center mt-8">입점문의</p>
          <p class="lg:text-content-size lg_tall:text-content-size sm:text-content-size text-center">입점신청</p>
        </div>
        <p class="lg:ml-10 lg_tall:ml-10 text-4xl text-main-0 font-semibold sm:rotate-90">></p>
        <div class="flex flex-col items-center justify-between">
          <i class="fa-solid fa-people-carry-box"
          :class="[isMobile ? 'fa-3x' : 'fa-6x']">
          </i>
          <p class="lg:text-content-size lg_tall:text-content-size sm:text-content-size text-center mt-8">전문</p>
          <p class="lg:text-content-size lg_tall:text-content-size sm:text-content-size text-center">담당자 배정</p>
        </div>
        <p class="lg:ml-10 lg_tall:ml-10 text-4xl text-main-0 font-semibold sm:rotate-90">></p>
        <div class="flex flex-col items-center justify-between">
          <i class="fa-regular fa-rectangle-list"
          :class="[isMobile ? 'fa-3x' : 'fa-6x']">
          </i>
          <p class="lg:text-content-size lg_tall:text-content-size sm:text-content-size text-center mt-8">양사</p>
          <p class="lg:text-content-size lg_tall:text-content-size sm:text-content-size text-center">계약서 검토</p>
        </div>
        <p class="lg:ml-10 lg_tall:ml-10 text-4xl text-main-0 font-semibold sm:rotate-90">></p>
        <div class="flex flex-col items-center justify-between">
          <i class="fa-solid fa-handshake-simple"
          :class="[isMobile ? 'fa-3x' : 'fa-6x']">
          </i>
          <p class="lg:text-content-size lg_tall:text-content-size sm:text-content-size text-center mt-8">계약체결</p>
          <p class="lg:text-content-size lg_tall:text-content-size sm:text-content-size text-center">제품 자료 확인</p>          
        </div>
        <p class="lg:ml-10 lg_tall:ml-10 text-4xl text-main-0 font-semibold sm:rotate-90">></p>
        <div class="flex flex-col items-center justify-between">
          <i class="fa-solid fa-thumbs-up"
          :class="[isMobile ? 'fa-3x' : 'fa-6x']">
          </i>
          <p class="lg:text-content-size lg_tall:text-content-size sm:text-content-size text-center mt-8">입점완료</p>
          <p class="lg:text-content-size lg_tall:text-content-size sm:text-content-size text-center">판매시작</p>
        </div>
      </div>
    </div>

    <!-- 문의사항 -->
    <Qna_1  id="contact-us" propPlatform="2" title="겟아이즈에" description="고객 맞춤 컨설팅을 제공합니다."/>
    
  </div>
  <Footer />
</template>
<script>
import ServiceInfo_04 from '@/components/baseComponents/ServiceInfo_04.vue'
import Header from '@/components/layoutComponents/Header.vue'
import Footer from '@/components/layoutComponents/Footer.vue'
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Qna from '@/components/baseComponents/Qna.vue'
import Qna_1 from '@/components/baseComponents/Qna_1.vue'
export default {
  components: { ServiceInfo_04, Header, Footer, Qna, Qna_1 },

  data() {
    return {
      downLoadCount: 0,
      userCount: 0,
      gorwCount: 0,
      customerCount: 0,

      qnaData: {
        isShowplatform: false,
        smallTitle: '겟아이즈 문의하기',
        title: '제품 판매를 우선적으로 돕는<br>겟아이즈를 신청 할 수 있습니다.',
        detailList: ['✔️ 평일 2일 내, 답변 드립니다.',
          '✔️ 문의하실 플랫폼을 선택 해 주세요.', '✔️ 필요시 세부정보를 이메일로 보내드립니다. (서비스 제공, 솔루션 문의 등)'
        , '✔️ 전화 상담을 원하신다면 02-2085-0606로 연락 부탁드립니다.']
      },
    }
  },

  computed: {
    isMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /iPhone|iPad|iPod|Android/i.test(userAgent);
    },
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to(this.$data, {
      downLoadCount: 60000,
      userCount: 10000,
      gorwCount: 35,
      customerCount: 98,
      duration: 1,
      scrollTrigger: {
        trigger: ".scrolltrigger",
        start: "top center bottom",
        toggleActions: "restart resume pause",
        onEnter: () => {
          this.downLoadCount = 0; // 스크롤 트리거가 시작되면 숫자를 0으로 초기화
          this.userCount = 0;
          this.gorwCount = 0;
          this.customerCount = 0;
        }
      },
      onUpdate: () => {
        this.downLoadCount = Math.round(this.downLoadCount);
        this.userCount = Math.round(this.userCount);
        this.gorwCount = Math.round(this.gorwCount);
        this.customerCount = Math.round(this.customerCount);
      }
    });
  },

}
</script>
<template>
    <div :class="wrapperClass">
      <div class="lg:flex lg:flex-col gap-[10px]">
        <p class="text-main-1">{{ name }}</p>
        <p class="lg:text-content-size font-medium text-gray_1">{{ description }}</p>
      </div>
      <img class="lg:w-[72px] lg:h-[72px]" :src="avaratUrl" alt="">
    </div>
  </template>
  
  <script>
  export default {
    props: {
      avaratUrl: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      },
      width: {
        type: Number,
        default: 646 // 기본값 예시
      },
      height: {
        type: Number,
        default: 104 // 기본값 예시
      }
    },
    computed: {
      wrapperClass() {
        return [
          // px 단위 적용 -> w-[646px], h-[104px] 등의 Tailwind 임의 값 문법 사용
          `w-[${this.width}px]`,
          `h-[${this.height}px]`,
          // 기존 클래스들
          'lg:flex',
          'lg:justify-between',
          'lg:pl-8',
          'lg:pr-6',
          'lg:items-center',
          'border-main-0',
          'border',
          'rounded-xl',
          'bg-[#F3FAF9]'
        ]
      }
    }
  }
  </script>
  
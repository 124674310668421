<template>
  <Header />
  <div class="w-full font-basic mt-28">
    <!-- PC 상단  -->
    <div class="lg:w-[1280px] lg:h-[468px] lg:flex lg:justify-between lg:mx-auto lg:items-center sm:hidden">
      <div class="lg:flex lg:flex-col lg:ml-[80px] justify-center">
        <img class="w-[222px] h-[48px]" src="@/assets/qmeet_logo.png" alt="qmeet-logo">
        <div class="lg:mt-5 lg:font-bold lg:text-[44px] lg:text-text-0 lg:leading-[52px] lg:tracking-[-0.0045em]">
          <p>국내 최초 QA전문</p>
          <p>아웃소싱 플랫폼</p>
        </div>
        <div class="lg:mt-2 lg:text-content-size text-gray lg:leading-[26px] lg:tracking-[-0.0045em]">
          <p>고객사 프로젝트를 내 일처럼 해주는</p>
          <p>아웃소싱사를 가장 빠르게 찾는 방법</p>
        </div>
        <button class="lg:flex lg:mt-5 items-center" @click="goToUrl">
          <p class="lg:text-content-size text-main-0 items-center">홈페이지 바로가기</p>
          <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 3.75C16.5563 3.75 20.25 7.44365 20.25 12C20.25 16.5563 16.5563 20.25 12 20.25C7.44365 20.25 3.75 16.5563 3.75 12C3.75 7.44365 7.44365 3.75 12 3.75Z"
              stroke="#1BA494" stroke-width="1.5" />
            <path
              d="M20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12Z"
              stroke="#1BA494" stroke-width="1.5" stroke-miterlimit="10" />
            <path d="M11 15L14 12L11 9" stroke="#1BA494" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </button>
      </div>
      <!-- 영상 -->
      <div class="lg:w-[640px] lg:h-[360px] rounded-lg border-8 border-gray_3 overflow-hidden">
        <iframe class="-z-10" width="640" height="360" src="https://www.youtube.com/embed/Lby21IZf4mU" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture fullscreen"
          allowfullscreen></iframe>
      </div>
    </div>

    <!--모바일 상단-->
    <div class="flex flex-col px-5 pb-10 mx-auto lg:hidden justify-center">
      <img class="sm:w-[222px]" src="@/assets/qmeet_logo.png" alt="">
      <div class="sm:mt-5 sm:leading-[32px] sm:tracking-[-0.0045em] text-text-0 font-bold sm:text-medium-size-2">
        <p>국내 최초 QA전문</p>
        <p>아웃소싱 플랫폼</p>
      </div>
      <div class="sm:mt-2 sm:leading-[24px] sm:tracking-[-0.0045em] text-gray font-medium">
        <p>고객사 프로젝트를 내 일처럼 해주는</p>
        <p>아웃소싱사를 가장 빠르게 찾는 방법</p>
      </div>
      <button class="sm:flex sm:mt-5 sm:items-center" @click="goToUrl">
        <p class="text-main-0 items-center">홈페이지 바로가기</p>
        <svg class="sm:ml-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 3.75C16.5563 3.75 20.25 7.44365 20.25 12C20.25 16.5563 16.5563 20.25 12 20.25C7.44365 20.25 3.75 16.5563 3.75 12C3.75 7.44365 7.44365 3.75 12 3.75Z"
            stroke="#1BA494" stroke-width="1.5" />
          <path
            d="M20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12Z"
            stroke="#1BA494" stroke-width="1.5" stroke-miterlimit="10" />
          <path d="M11 15L14 12L11 9" stroke="#1BA494" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </button>
      <!-- Tailwind 3.2 이상 + aspect-ratio 유틸리티 사용 -->
      <!-- aspect-ratio 활용 (Tailwind 3.2+) -->
      <div class="sm:w-[320px] sm:h-[180px] rounded-lg border-8 border-gray_3 overflow-hidden mt-10">
        <iframe class="-z-10 sm:w-[320px] sm:h-[180px]" src="https://www.youtube.com/embed/Lby21IZf4mU" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
      </div>
    </div>

    <!-- PC 아카이빙 플랫폼  -->
    <div class="lg:w-[1280px] lg:h-[726px] lg:mx-auto lg:flex lg:flex-col lg:items-center lg:pt-10 sm:hidden">
      <p class="text-text-0 font-medium lg:text-medium-size-1">큐밋은 기업대상(B2B) 서비스로, 고객사 - QA 아웃소싱사를 연결하는 아카이빙
        플랫폼 입니다.</p>
      <img class="lg:mt-[60px]" src="@/assets/qmeet_archive.png" alt="">
      <div
        class="lg:flex lg:flex-col lg:items-center lg:text-medium-size-2 lg:tracking-[-0.0045em] lg:leading-[36px] lg:mt-[60px] text-text-1">
        <p>고객사는 프로젝트 등록하면 24시간 내 아웃소싱사 지원 알람!</p>
        <p>큐밋에서는 공고등록부터 계약, 프로젝트 수행관리까지 쉽고, 간편하게 관리합니다.</p>
      </div>
    </div>

    <!-- 모바일 아카이빙 플랫폼  -->
    <div class="sm:flex sm:flex-col sm:px-5 sm:pb-10 sm:mx-auto sm:py-10 lg:hidden sm:justify-center">
      <p class="font-medium text-[18px] text-center leading-[26px] tracking-[-0.0045em]">큐밋은 기업대상(B2B) 서비스로,<br>고객사 - QA
        아웃소싱사를 연결하는<br>아카이빙 플랫폼 입니다.</p>
      <img class="mt-7" src="@/assets/qmeet_archive.png" alt="">
      <p class="text-text-1 text-[13px] text-center mt-7 leading-[18px] tracking-[-0.0045em]">고객사는 프로젝트 등록하면 24시간 내
        아웃소싱사 지원 알람!
        큐밋에서는 공고등록부터 계약, 프로젝트 수행관리까지<br>쉽고, 간편하게 관리합니다.</p>
    </div>

    <!-- PC 고객의 소리  -->
    <div class="lg:w-full lg:h-[628px] lg:mx-auto lg:flex lg:flex-col lg:items-center lg:pt-10 bg-gray_2 sm:hidden">
      <p class="font-bold text-text-0 lg:text-medium-size-0">고객의 소리</p>

      <div class="customer-review lg:flex lg:w-[1200px] lg:gap-x-[30px] lg:gap-y-10 lg:mt-[60px]">

        <!-- 이커머스 **사 장보리님 -->
        <div class="lg:flex lg:w-[546px] lg:h-[104px] lg:justify-start lg:py-4 bg-white rounded-xl lg:pl-4 lg:gap-6">
          <img class="lg:w-[78px] lg:h-[78px]" src="@/assets/ic_avarar_007.png" alt="">
          <div class="lg:flex lg:flex-col gap-[10px]">
            <p class="text-main-1">이커머스 **사</p>
            <p class="text-gray_1 lg:text-content-size">우리회사는 테스트 운영 예산이 적은데 가능할까요?</p>
          </div>
        </div>

        <!-- 이커머스 **사 장보리님 답변 -->
        <div
          class="lg:flex lg:w-[624px] lg:justify-between lg:pl-8 lg:pr-6 lg:h-[104px] border border-main-0 rounded-xl bg-[#F3FAF9]">
          <div class="lg:flex lg:flex-col lg:mt-4">
            <p class="text-main-1">큐밋 매니저</p>
            <p class="text-gray_1 lg:text-content-size lg:mt-[10px]">아웃소싱을 통한 유연한 장·단기 프로젝트가 운영 가능합니다.</p>
          </div>
          <img class="lg:w-[78px] lg:h-[78px] lg:mt-3" src="@/assets/ic_avarar_008.png" alt="">
        </div>

      </div>

      <div class="customer-review lg:flex lg:w-[1200px] lg:gap-x-[30px] lg:gap-y-10 lg:mt-10">

        <!-- 금융 **사 박머니님 -->
        <div class="lg:flex lg:w-[526px] lg:h-[104px] lg:justify-start lg:py-4 bg-white rounded-xl lg:pl-4 lg:gap-6">
          <img class="lg:w-[78px] lg:h-[78px]" src="@/assets/ic_avarar_009.png" alt="">
          <div class="lg:flex lg:flex-col gap-[10px]">
            <p class="text-main-1">금융 **사</p>
            <p class="text-gray_1 lg:text-content-size">큐밋에서 검증된 QA 파트너와 협업 가능한가요?</p>
          </div>
        </div>

        <!-- 금융 **사 박머니님 답변 -->
        <div
          class="lg:flex lg:w-[644px] lg:justify-between lg:pl-8 lg:pr-6 lg:h-[104px] border border-main-0 rounded-xl bg-[#F3FAF9]">
          <div class="lg:flex lg:flex-col lg:mt-4">
            <p class="text-main-1">큐밋 매니저</p>
            <p class="text-gray_1 lg:text-content-size lg:mt-[10px]">검증된 QA 파트너스를 통한 서비스 질을 향상 시킬 수 있어요!</p>
          </div>
          <img class="lg:w-[78px] lg:h-[78px] lg:mt-3" src="@/assets/ic_avarar_010.png" alt="">
        </div>

      </div>

      <div class="customer-review lg:flex lg:w-[1200px] lg:gap-x-[30px] lg:gap-y-10 lg:mt-10">

        <!-- 자동차 **사 김운전님  -->
        <div class="lg:flex lg:w-[482px] lg:h-[122px] lg:justify-start lg:py-4 bg-white rounded-xl lg:pl-4 lg:gap-6">
          <img class="lg:w-[78px] lg:h-[78px]" src="@/assets/ic_avarar_011.png" alt="">
          <div class="lg:flex lg:flex-col gap-[10px]">
            <p class="text-main-1">자동차 **사</p>
            <p class="text-gray_1 lg:text-content-size">당장 프로젝트를 시작하고 싶은데<br>큐밋에는 지금 당장 투입 될 인재가 있나요?</p>
          </div>
        </div>

        <!-- 자동차 **사 김운전님 답변 -->
        <div
          class="lg:flex lg:w-[688px] lg:justify-between lg:pl-8 lg:pr-6 lg:h-[122px] border border-main-0 rounded-xl bg-[#F3FAF9]">
          <div class="lg:flex lg:flex-col lg:mt-4">
            <p class="text-main-1">큐밋 매니저</p>
            <p class="text-gray_1 lg:text-content-size lg:mt-[10px]">투입이 필요한 프로젝트에 적합한 엔지니어 선정과 선발 시간 단축<br>이 가능합니다.</p>
          </div>
          <img class="lg:w-[78px] lg:h-[78px] lg:mt-3" src="@/assets/ic_avarar_008.png" alt="">
        </div>

      </div>


    </div>

    <!-- 모바일 고객의 소리 진행중 -->
    <div class="sm:flex sm:flex-col sm:px-5 sm:pb-10 sm:mx-auto sm:py-10 lg:hidden sm:justify-center bg-gray_2">
      <p class="font-bold text-center text-medium-size-2 text-text-0 mb-7">고객의 소리</p>
      <div class="flex flex-col gap-3 customer-review">
        <!-- 대형 온라인 서점 -->
        <CustomerVoice_M_1 :avaratUrl="require('@/assets/ic_avatar_001.png')" name="이커머스 **사"
          description="우리회사는 테스트 운영 예산이<br>적은데 가능할까요?"
          descriptionStype="font-medium text-small-size text-gray_1 leading-[145%]" />

        <!-- 큐밋매니저 -->
        <CustomerVoice_M_2 :avaratUrl="require('@/assets/ic_avatar_002.png')" name="큐밋 매니저"
          description="아웃소싱을 통한 유연한 장·단기<br>프로젝트가 운영 가능합니다."
          descriptionStype="font-medium text-small-size text-gray_1 leading-[145%]"
          rootStyle="sm:flex sm:gap-4 sm:py-4 sm:pl-3 sm:pr-4 rounded-2xl border-main-0 border bg-[#F3FAF9]" />

        <!-- 금융 **사 -->
        <CustomerVoice_M_1 :avaratUrl="require('@/assets/ic_avatar_003.png')" name="금융 **사"
          description="큐밋에서 검증된  QA 파트너와 협업 가능한가요?"
          descriptionStype="font-medium text-small-size text-gray_1 leading-[145%]" />

        <!-- 큐밋매니저 -->
        <CustomerVoice_M_2 :avaratUrl="require('@/assets/ic_avatar_004.png')" name="큐밋 매니저"
          description="검증된 QA 파트너스를 통한 서비스 질을 향상 시킬 수 있어요!"
          descriptionStype="font-medium text-small-size text-gray_1 leading-[145%]"
          rootStyle="sm:flex sm:gap-4 sm:py-4 sm:pl-3 sm:pr-4 rounded-2xl border-main-0 border bg-[#F3FAF9]" />

        <!-- 자동차 **사 김운전님  -->
        <CustomerVoice_M_1 :avaratUrl="require('@/assets/ic_avatar_005.png')" name="자동차 **사"
          description="당장 프로젝트를 시작하고 싶은데<br>큐밋에는 지금 당장 투입 될 인재가 있나요?"
          descriptionStype="font-medium text-small-size text-gray_1 leading-[145%]" />

        <!-- 큐밋매니저 -->
        <CustomerVoice_M_2 :avaratUrl="require('@/assets/ic_avatar_004.png')" name="큐밋 매니저"
          description="투입이 필요한 프로젝트에 적합한<br>엔지니어 선정과 선발 시간 단축이 가능합니다."
          descriptionStype="font-medium text-small-size text-gray_1 leading-[145%]"
          rootStyle="sm:flex sm:gap-4 sm:py-4 sm:pl-3 sm:pr-4 rounded-2xl border-main-0 border bg-[#F3FAF9]" />

      </div>
    </div>

    <!-- PC 큐밋 서비스 소개  -->
    <div class="lg:w-[1280px] lg:pb-20 lg:mx-auto lg:flex lg:flex-col lg:items-center lg:pt-10 sm:hidden">
      <p class="font-bold text-text-0 lg:text-medium-size-0">큐밋 서비스 소개</p>

      <div class="lg:w-[1280px] lg:flex lg:flex-col lg:gap-24 lg:mt-[60px]">

        <!--패스트 매칭 서비스-->
        <div class="lg:flex lg:gap-10 lg:justify-start">
          <img class="lg-640[px] lg-h-[400px]" src="@/assets/qmeet_service_001.png" alt="">
          <div class="lg:flex lg:flex-col lg:justify-center">
            <p class="text-main-0">성공적 프로젝트를 위한 가장 쉽고 빠른</p>
            <p class="text-text-0 lg:font-semibold lg:text-medium-size-1">패스트 매칭서비스</p>
            <ul class="lg:mt-5 text-text-1 lg:leading-[26px] lg:tracking-[-0.0045em]">
              <li>공고 등록 후 24시간 내 파트너사 지원 발생, 2~4주내 엔지니어 배치 완료!</li>
              <li>등록부터 아웃소싱 계약까지 <span class="text-text-1 font-bold">큐밋 사용 비용은 0원</span></li>
              <li>장/단기 프로젝트 상관없이, 단독/컨소시움 파트너스 매칭이 가능합니다.</li>
            </ul>
          </div>
        </div>

        <!-- 올인원 관리 서비스-->
        <div class="lg:flex lg:gap-10 lg:justify-between">
          <div class="lg:flex lg:flex-col lg:justify-center">
            <p class="text-main-0">매칭 - 계약 - 관리 - 대급지급까지 한번에</p>
            <p class="text-text-0 lg:font-semibold lg:text-medium-size-1">올인원 관리 서비스</p>
            <ul class="lg:mt-5 text-text-1 lg:leading-[26px] lg:tracking-[-0.0045em]">
              <li>수행/지원 내역통해 지원현황, 인터뷰 요청, 합격 등 쉬운 커뮤니케이션 기능 제공</li>
              <li>고객사가 쉽고 편한 프로젝트 수행 및 돌발사항 관리 가능</li>
              <li>큐밋에서 결과 리포트 검수 후 -> 대급지급 -> 파트너스에 대금 전달 완료</li>
            </ul>
          </div>
          <img class="lg-640[px] lg-h-[400px]" src="@/assets/qmeet_service_002.png" alt="">
        </div>

        <!-- 토탈 매니징 서비스 서비스-->
        <div class="lg:flex lg:gap-10 lg:justify-start">
          <img class="lg-640[px] lg-h-[400px]" src="@/assets/qmeet_service_003.png" alt="">
          <div class="lg:flex lg:flex-col lg:justify-center">
            <p class="text-main-0">20년 업력 노하우 담은 고객사 맞춤형 관리</p>
            <p class="text-text-0 lg:font-semibold lg:text-medium-size-1">토탈 매니징 서비스</p>
            <ul class="lg:mt-5 text-text-1 lg:leading-[26px] lg:tracking-[-0.0045em]">
              <li>QA 직무 경력 20년 이상의 큐밋 매니저를 배정하여 엔지니어 검증/케어 시작</li>
              <li>엔지니어 채용도 큐밋 매니저가 해결(단독, 컨소시엄 모두 해당)</li>
              <li>채용이 끝이 아닙니다. 프로젝트 수행 및 종료까지 케어 지원합니다.</li>
            </ul>
          </div>
        </div>

        <!-- QA 툴 스토어(2025년 6월 오픈예정)-->
        <div class="lg:flex lg:gap-10 lg:justify-between">
          <div class="lg:flex lg:flex-col lg:justify-center">
            <p class="text-main-0">테스트 솔루션과 툴의 모든 아카이빙</p>
            <p class="text-text-0 lg:font-semibold lg:text-medium-size-1">QA 툴 스토어(2025년 6월 오픈예정)</p>
            <ul class="lg:mt-5 text-text-1 lg:leading-[26px] lg:tracking-[-0.0045em]">
              <li>테스트 관련 솔루션 · 자동화 툴을 큐밋에서 한 번에 보여줍니다.</li>
              <li>프로젝트에 맞는 틀을 비교하고, 구매까지 가능한 서비스를 제공합니다.</li>
              <li>해당 툴을 교육받은 엔지니어들을 추천 및 매칭하여 업무 효율화 증대</li>
            </ul>
          </div>
          <img class="lg-640[px] lg-h-[400px]" src="@/assets/qmeet_service_004.png" alt="">
        </div>
      </div>

    </div>

    <!-- 모바일 큐밋 서비스 소개  -->
    <div class="sm:flex sm:flex-col sm:px-5 sm:pb-10 sm:mx-auto sm:py-10 lg:hidden sm:justify-center">
      <p class="font-bold text-center text-medium-size-2 text-text-0 mb-7">큐밋 서비스 소개</p>
      <div class="flex flex-col gap-[60px]">
        <!-- 패스트 매칭 서비스-->
        <div class="flex flex-col">
          <img src="@/assets/qmeet_service_001.png" alt="">
          <p class="text-small-size-1 text-main-0 lg:tracking-[-0.0045em] leading-[18px] mt-7">성공적 프로젝트를 위한 가장 쉽고 빠른
          </p>
          <p class="font-semibold text-text-0 text-content-size mt-1">패스트 매칭서비스</p>
          <ul class="mt-4 text-small-size-1 leading-[18px] text-text-1 tracking-[-0.0045em]">
            <li>공고 등록 후 24시간 내 파트너사 지원 발생, 2~4주내 엔지니어 배치 완료!</li>
            <li>등록부터 아웃소싱 계약까지 <span class="font-bold text-text-1">큐밋 사용 비용은 0원</span></li>
            <li>장/단기 프로젝트 상관없이, 단독/컨소시움 파트너스<br>매칭이 가능합니다.</li>
          </ul>
        </div>
        <!-- 올인원 관리 서비스-->
        <div class="flex flex-col">
          <img src="@/assets/qmeet_service_002.png" alt="">
          <p class="text-small-size-1 text-main-0 lg:tracking-[-0.0045em] leading-[18px] mt-7">매칭 - 계약 - 관리 - 대급지급까지 한번에
          </p>
          <p class="font-semibold text-text-0 text-content-size mt-1">올인원 관리 서비스</p>
          <ul class="mt-4 text-small-size-1 leading-[18px] text-text-1 tracking-[-0.0045em]">
            <li>수행/지원 내역통해 지원현황, 인터뷰 요청, 합격 등<br>쉬운 커뮤니케이션 기능 제공</li>
            <li>고객사가 쉽고 편한 프로젝트 수행 및 돌발사항 관리<br>가능</li>
            <li>큐밋에서 결과 리포트 검수 후 → 대급지급 → 파트너스에 대금 전달 완료</li>
          </ul>
        </div>
        <!-- 토탈 매니징 서비스-->
        <div class="flex flex-col">
          <img src="@/assets/qmeet_service_003.png" alt="">
          <p class="text-small-size-1 text-main-0 lg:tracking-[-0.0045em] leading-[18px] mt-7">20년 업력 노하우 담은 고객사 맞춤형 관리
          </p>
          <p class="font-semibold text-text-0 text-content-size mt-1">토탈 매니징 서비스</p>
          <ul class="mt-4 text-small-size-1 leading-[18px] text-text-1 tracking-[-0.0045em]">
            <li>QA 직무 경력 20년 이상의 큐밋 매니저를 배정하여<br>엔지니어 검증/케어 시작</li>
            <li>엔지니어 채용도 큐밋 매니저가 해결(단독, 컨소시엄<br>모두 해당)</li>
            <li>채용이 끝이 아닙니다. 프로젝트 수행 및 종료까지<br>케어 지원합니다.</li>
          </ul>
        </div>
        <!-- QA 툴 스토어-->
        <div class="flex flex-col">
          <img src="@/assets/qmeet_service_004.png" alt="">
          <p class="text-small-size-1 text-main-0 lg:tracking-[-0.0045em] leading-[18px] mt-7">테스트 솔루션과 툴의 모든 아카이빙</p>
          <p class="font-semibold text-text-0 text-content-size mt-1">QA 툴 스토어(2025년 6월 오픈예정)</p>
          <ul class="mt-4 text-small-size-1 leading-[18px] text-text-1 tracking-[-0.0045em]">
            <li>테스트 관련 솔루션 · 자동화 툴을 큐밋에서 한 번에<br>보여줍니다.</li>
            <li>프로젝트에 맞는 틀을 비교하고, 구매까지 가능한<br>서비스를 제공합니다.</li>
            <li>해당 툴을 교육받은 엔지니어들을 추천 및 매칭하여<br>업무 효율화 증대</li>
          </ul>
        </div>
      </div>
    </div>

    <!-- PC 큐밋 프로세스  -->
    <div class="lg:w-full lg:h-[596px] lg:mx-auto lg:flex lg:flex-col lg:pt-10 lg:items-center bg-[#F3FAF9] sm:hidden">
      <p class="font-bold lg:text-medium-size-0 text-text-0 lg:tracking-[-0.0045em]">큐밋 프로세스</p>
      <p class="text-text-1 text-medium-size-1 lg:tracking-[-0.0045em] lg:mt-5">가입하고 5분안에 프로젝트 등록까지 완료!</p>

      <div class="lg:flex lg:mt-[60px]">
        <!-- Step 1 -->
        <div class="flex items-center">
          <div
            class="lg:w-[220px] lg:h-[220px] bg-main-2 text-[24px] font-medium rounded-full flex items-center justify-center text-white text-center">
            회원가입
          </div>
          <div class="lg:w-[60px] lg:h-5 bg-main-2"></div>
        </div>

        <!-- Step 2 -->
        <div class="flex items-center">
          <div
            class="lg:w-[220px] lg:h-[220px] bg-main-2 text-[24px] rounded-full flex items-center justify-center text-white font-medium text-center">
            무료 프로젝트 등록
          </div>
          <div class="lg:w-[60px] lg:h-5 bg-main-2"></div>
        </div>

        <!-- Step 3 -->
        <div class="flex items-center">
          <div
            class="lg:w-[220px] lg:h-[220px] bg-main-2 text-[24px] rounded-full flex items-center justify-center text-white font-medium text-center">
            지원한<br>아웃소싱사 확인
          </div>
          <div class="lg:w-[60px] lg:h-5 bg-main-2"></div>
        </div>

        <!-- Step 4 -->
        <div class="flex items-center">
          <div
            class="lg:w-[220px] lg:h-[220px] bg-main-2 text-[24px] rounded-full flex items-center justify-center text-white font-medium text-center">
            엔지니어 미팅요청
          </div>
          <div class="lg:w-[60px] lg:h-5 bg-main-2"></div>
        </div>

        <!-- Step 5 -->
        <div class="flex items-center">
          <div
            class="lg:w-[220px] lg:h-[220px] bg-main-2 text-[24px] rounded-full flex items-center justify-center text-white font-medium text-center">
            신청완료 및<br>프로젝트 수행
          </div>
        </div>
      </div>


      <Button
        class="rounded-full border-main-0 border-[1px] lg:flex lg:items-center lg:justify-center lg:w-[156px] lg:h-[44px]  lg:mt-[60px] bg-white"
        @click="goToRegProj">
        <p class="font-medium text-main-0 lg:text-small-size">무료 프로젝트 등록</p>
      </Button>

    </div>

    <!-- 모바일 큐밋 프로세스  -->
    <div class="sm:flex sm:flex-col sm:px-5 sm:mx-auto sm:py-10 lg:hidden sm:justify-center bg-[#F3FAF9]">
      <p class="font-bold text-center text-medium-size-2 text-text-0 mb-5">큐밋 프로세스</p>
      <p class="text-text-1 tracking-[-0.0045em] text-center">가입하고 5분안에 프로젝트 등록까지 완료!</p>
      <img class="mt-10" src="@/assets/ic_qmeet_mobile_process.png" alt="">
      <Button class="rounded-full border-main-0 border-[1px]  w-[156px] h-[44px] mt-[40px] mx-auto"
        @click="goToRegProj">
        <p class="font-medium text-main-0 text-small-size">무료 프로젝트 등록</p>
      </Button>
    </div>

    <!-- 문의  -->
    <Qna_1 id="contact-us" propPlatform="3" title="큐밋에" description="전담 매니저가 맞춤 아웃소싱사를 매칭해 드립니다." />
    <Footer />

  </div>
</template>
<script>
import Header from '@/components/layoutComponents/Header.vue'
import Footer from '@/components/layoutComponents/Footer.vue'
import CustomerVoice from '@/components/baseComponents/CustomerVoice.vue'
import CustomerVoice_1 from '@/components/baseComponents/CustomerVoice_1.vue'
import Qna_1 from '@/components/baseComponents/Qna_1.vue'
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import CustomerVoice_M_1 from '@/components/baseComponents/CustomerVoice_M_1.vue'
import CustomerVoice_M_2 from '@/components/baseComponents/CustomerVoice_M_2.vue'
export default {
  components: {
    Header, Footer, CustomerVoice, CustomerVoice_1, Qna_1, CustomerVoice_M_1, CustomerVoice_M_2
  },
  data() {
    return {
    }
  },
  methods: {
    goToRegProj() {
      // 현재 탭에서 이동
      window.open('https://www.q-meet.com', '_blank');
    },
    goToUrl() {
      // 특정 URL로 이동
      window.open('https://www.q-meet.com', '_blank');
    },
    scrollToTarget(targetId) {
      const targetElement = document.getElementById(targetId);
      if (!targetElement) return;

      // 원하는 만큼 윗쪽으로 더 스크롤하기 위해 offset 설정 (예: 100px)
      const offset = 100;
      // getBoundingClientRect().top + 현재 스크롤 위치 - offset
      const yPosition = targetElement.getBoundingClientRect().top + window.scrollY - offset;

      window.scrollTo({
        top: yPosition,
        behavior: 'smooth'
      });
    },
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    // 각 customer-review 내부의 자식 div를 개별적으로 처리
    gsap.utils.toArray('.customer-review').forEach((container) => {
      const children = container.children; // customer-review의 자식 요소들
      gsap.fromTo(
        children,
        {
          opacity: 0,
          y: 50, // 아래에서 위로 올라오는 초기 상태
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power2.out',
          stagger: 0.5, // 질문과 답변 간 시간차
          scrollTrigger: {
            trigger: container,
            start: 'top 80%', // 뷰포트의 80% 지점에서 트리거
            toggleActions: 'play none none none', // 트리거 동작
          },
        }
      );
    });
  },

}
</script>
<style scoped>
.customer-review>div {
  opacity: 0;
  /* 초기 상태 */
  transform: translateY(50px);
  /* 아래에서 시작 */
}

ul {
  list-style-type: disc;
  /* 기본 불릿 스타일 */
  padding-left: 20px;
  /* 불릿이 보이도록 패딩 추가 */
}

li {
  margin-bottom: 10px;
  /* 리스트 항목 간격 */

}
</style>